import React, { useContext, useEffect } from "react";
import { FilterContext } from "../../contexts/FilterContext";

const FilterTag = ({ tag, isActive }) => {
  const { filter, changeFilter } = useContext(FilterContext);

  return (
    <div
      onClick={() => {
        changeFilter(tag);
      }}
      className={`team-filter-tag ${isActive && "active"}`}
    >
      {tag}
    </div>
  );
};

export default FilterTag;
