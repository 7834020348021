import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import parser from "html-react-parser";
import { LangContext } from "../../contexts/LangContext";
import useWindowSize from "../../customs/useWindowSize";

const procedureData = [
  {
    IDN: [
      {
        img: "procedure1.png",
        title: "Interview Awal",
      },
      {
        img: "procedure2.png",
        title: "Proses Asesmen",
      },
      {
        img: "procedure3.png",
        title: "Konsultasi<br/>dengan Ahli",
      },
      {
        img: "procedure4.png",
        title: "Debriefing",
      },
    ],

    ENG: [
      {
        img: "procedure1.png",
        title: "Preliminary<br/>Consultation",
      },
      {
        img: "procedure2.png",
        title: "Assessment Process",
      },
      {
        img: "procedure3.png",
        title: "Consultation with <br/>Experts",
      },
      {
        img: "procedure4.png",
        title: "Debriefing",
      },
    ],
  },
];
const Procedure = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <>
      {!isMobile ? (
        <div className="about-procedure-container">
          <h1 className="procedure-title">PEACE Assessment Procedure</h1>

          <div className="procedure-card-container">
            {procedureData.map((data) =>
              lang === "ENG"
                ? data.ENG.map((val, index) => (
                    <>
                      <div key={index} className="procedure-card">
                        <img
                          className="procedure-img"
                          src={process.env.PUBLIC_URL + "/procedure/" + val.img}
                          alt="Procedure Icon"
                        />
                        <p className="procedure-title">{parser(val.title)}</p>
                      </div>

                      {index + 1 !== data.ENG.length && (
                        <img
                          className="procedure-arrow"
                          src={process.env.PUBLIC_URL + "/procedure/Arrow.png"}
                        />
                      )}
                    </>
                  ))
                : data.IDN.map((val, index) => (
                    <>
                      <div key={index} className="procedure-card">
                        <img
                          className="procedure-img"
                          src={process.env.PUBLIC_URL + "/procedure/" + val.img}
                          alt="Procedure Icon"
                        />
                        <p className="procedure-title">{parser(val.title)}</p>
                      </div>

                      {index + 1 !== data.IDN.length && (
                        <img
                          className="procedure-arrow"
                          src={process.env.PUBLIC_URL + "/procedure/Arrow.png"}
                        />
                      )}
                    </>
                  ))
            )}
          </div>
        </div>
      ) : (
        <div className="mobile-about-procedure-container">
          <h1 className="procedure-title">PEACE Assessment Procedure</h1>

          <div className="mobile-procedure-card-container">
            <div className="mobile-procedure-card-top">
              <div className="mobile-producer-card">
                <img
                  className="mobile-producer-image"
                  src={
                    process.env.PUBLIC_URL +
                    "/procedure/" +
                    procedureData[0].ENG[0].img
                  }
                  alt={procedureData[0].ENG[0].title}
                />
                <p>
                  {lang === "ENG"
                    ? parser(procedureData[0].ENG[0].title)
                    : parser(procedureData[0].IDN[0].title)}
                </p>
              </div>

              <img
                className="procedure-arrow right"
                src={process.env.PUBLIC_URL + "/procedure/Arrow.png"}
              />

              <div className="mobile-producer-card">
                <img
                  className="mobile-producer-image"
                  src={
                    process.env.PUBLIC_URL +
                    "/procedure/" +
                    procedureData[0].ENG[1].img
                  }
                  alt={procedureData[0].ENG[1].title}
                />
                <p>
                  {lang === "ENG"
                    ? parser(procedureData[0].ENG[1].title)
                    : parser(procedureData[0].IDN[1].title)}
                </p>

                <img
                  className="procedure-arrow down"
                  src={process.env.PUBLIC_URL + "/procedure/Arrow.png"}
                />
              </div>
            </div>

            <div
              className="mobile-procedure-card-bottom"
              style={{ marginTop: "2rem" }}
            >
              <div className="mobile-producer-card">
                <img
                  className="mobile-producer-image"
                  src={
                    process.env.PUBLIC_URL +
                    "/procedure/" +
                    procedureData[0].ENG[3].img
                  }
                  alt={procedureData[0].ENG[3].title}
                />
                <p>
                  {lang === "ENG"
                    ? parser(procedureData[0].ENG[3].title)
                    : parser(procedureData[0].IDN[3].title)}
                </p>
              </div>

              <img
                className="procedure-arrow left"
                src={process.env.PUBLIC_URL + "/procedure/Arrow.png"}
              />

              <div className="mobile-producer-card">
                <img
                  className="mobile-producer-image"
                  src={
                    process.env.PUBLIC_URL +
                    "/procedure/" +
                    procedureData[0].ENG[2].img
                  }
                  alt={procedureData[0].ENG[2].title}
                />
                <p>
                  {lang === "ENG"
                    ? parser(procedureData[0].ENG[2].title)
                    : parser(procedureData[0].IDN[2].title)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Procedure;
