import React, { useContext } from "react";
import Banner from "../components/Services/Banner";
import ServicesCard from "../components/Services/ServicesCard";
import Footer from "../components/Home/Footer";
import { LangContext } from "../contexts/LangContext";
import { Helmet } from "react-helmet";

const ServicesData = {
  ENG: [
    {
      title: "Psychoeducational Assessment",
      img: "Psychoeducational Assessment.png",
      desc: "Assessments of cognitive ability (IQ), psychological and personality test, developmental examination, test of school readiness, talent examination and finding interests for children, adolescents, and adults.",
    },
    {
      title: "Social Skills Services",
      img: "Social Skills Services.png",
      desc: "Service aimed to improve children’s social interaction and communication skills. This program encourages children to be involved in Social Skills Group Sessions where they will be paired with same-aged friends to practice communication strategies.",
    },
    {
      title: "ABA Therapy or Applied<br/>Behaviour Analysis",
      img: "ABA.png",
      desc: "Intervention program which focuses on the behavior of each child. Observations at the child’s home, center, and school environment are conducted to determine their abilities and needs in order to develop intervention programs that are tailored to the child's condition.",
    },
    {
      title: "Learning Support",
      img: "Learning Support.png",
      desc: "Consultation and training for schools and teachers on matters relating to treatment of children with learning disabilities and special needs children. This service also offers assistance to teachers and parents in the preparation of educational programs (Individual Education Plan/IEP) or education recommendations tailored to the needs of each child.",
    },
    {
      title: "Play & Creative Art Therapy",
      img: "Play and Creative Therapy.png",
      desc: "Intervention program using sand, art, drama, music, puppets, and creative visualization to help children with emotional problems, psychological issues and trauma.",
    },
    {
      title: "Speech Therapy",
      img: "Speech Therapy.png",
      desc: "Intervention program which helps children experiencing difficulty in producing sounds or words, stuttering, difficulty in understanding spoken language, and problems using appropriate vocabulary.",
    },
    {
      title: "Group & Individual Counseling",
      img: "Individual & Group Counseling.png",
      desc: "Counseling sessions to help children, teens, parents and families cope with emotions so that they can live a normal life without fear, confusion, anxiety, or trauma.",
    },
    {
      title: "Filial Play",
      img: "Filial Play.png",
      desc: "Program designed to help parents create a relationship of mutual respect, understanding, and trust with their child. This program helps children become more confident and show good behavior.",
    },
    {
      title: "Occupational Therapy<br/>& Sensory Integration",
      img: "Occupational Therapy .png",
      desc: "An intervention which aim to improve motor skills (fine and gross), sensory integration, visual perception, and cognitive function skills to support children's ability to learn and be able to participate in daily activities as well as to reach their full potential.",
    },
  ],
  IDN: [
    {
      title: "Asesmen Psikoedukasi",
      img: "Psychoeducational Assessment.png",
      desc: "Pemeriksaan kemampuan kognitif (IQ), pemeriksaan psikologis dan kepribadian, pemeriksaan tumbuh kembang, tes kesiapan sekolah, tes minat dan bakat untuk anak-anak, remaja, dan orang dewasa.",
    },
    {
      title: "Social Skills Services",
      img: "Social Skills Services.png",
      desc: "Layanan yang bertujuan untuk meningkatkan kemampuan anak dalam interaksi sosial dan komunikasi. Program ini mendorong anak untuk ikut terlibat dalam Social Skills Group Session di mana anak akan dipasangkan dengan teman sebaya untuk berlatih strategi komunikasi yang telah diajarkan.",
    },
    {
      title: "Terapi ABA atau Applied<br/>Behavior Analysis",
      img: "ABA.png",
      desc: "Intervensi yang berfokus pada perilaku masing-masing anak. Observasi di lingkungan rumah, center, dan sekolah dilakukan untuk mengetahui kemampuan dan kebutuhan anak guna mengembangkan program intervensi yang sesuai dengan kondisi anak.",
    },
    {
      title: "Learning Support",
      img: "Learning Support.png",
      desc: "Konsultasi dan pelatihan untuk sekolah dan guru mengenai hal-hal yang berkaitan dengan penanganan anak berkesulitan belajar dan berkebutuhan khusus di sekolah. Layanan ini juga menawarkan bantuan kepada guru dan orang tua dalam persiapan program pendidikan <em>(Individual Education Plan/IEP)</em> atau rekomendasi pendidikan yang sesuai dengan kebutuhan anak.",
    },
    {
      title: "Play & Creative Art Therapy",
      img: "Play and Creative Therapy.png",
      desc: "Intervensi menggunakan pasir, seni, drama, musik, boneka, dan creative visualization untuk membantu anak dengan masalah emosi, psikologis dan trauma.",
    },
    {
      title: "Terapi Wicara",
      img: "Speech Therapy.png",
      desc: "Intervensi yang membantu anak dengan gagap atau kesulitan menghasilkan suara atau kata, memahami bahasa lisan, dan menggunakan kosakata yang tepat. ",
    },
    {
      title: "Konseling Individu & Kelompok",
      img: "Individual & Group Counseling.png",
      desc: "Sesi konseling untuk membantu anak, remaja, orang tua, dan keluarga mengatasi emosi sehingga mereka dapat hidup normal tanpa rasa takut, bingung, cemas, atau trauma.",
    },
    {
      title: "Filial Play",
      img: "Filial Play.png",
      desc: "Program yang dirancang untuk membantu orang tua menciptakan hubungan yang saling menghormati, pengertian, dan saling percaya dengan anak. Program ini dapat membantu anak menjadi lebih percaya diri dan menunjukkan perilaku yang baik.",
    },
    {
      title: "Terapi Okupasi<br/>& Sensori Integrasi",
      img: "Occupational Therapy .png",
      desc: "Intervensi yang bertujuan untuk meningkatkan kemampuan motorik (halus dan kasar), integrasi sensorik, persepsi visual, dan keterampilan fungsi kognitif untuk menunjang kemampuan anak dalam belajar dan dapat ikut serta dalam kegiatan sehari-hari serta mencapai potensi mereka sepenuhnya.",
    },
  ],
};

const Services = () => {
  const { lang, toogleLang } = useContext(LangContext);
  return (
    <div>
      <Helmet>
        <title>Services | Peace Wesbite</title>
      </Helmet>

      <Banner />

      <div className="services-container">
        {lang === "ENG"
          ? ServicesData.ENG.map((val, index) => (
              <ServicesCard
                key={index}
                desc={val.desc}
                img={val.img}
                title={val.title}
              />
            ))
          : ServicesData.IDN.map((val, index) => (
              <ServicesCard
                key={index}
                desc={val.desc}
                img={val.img}
                title={val.title}
              />
            ))}
      </div>

      <div style={{ marginTop: "2rem" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Services;
