import React, { useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import "../../../styles/mobile/InfoEmail.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import { sendEmail } from "../../../utils/emailService";

const MobileInfoEmail = () => {
  const [email, setEmail] = useState("");
  const phoneNumber = "6287784167880";
  const text = "Halo PEACE. Saya ingin bertanya mengenai layanan...";
  const btnEmail = "psikoedu.peace@gmail.com";

  return (
    <>
      <div className="mobile-info-email-container">
        <div className="mobile-info-email-top">
          <div className="mobile-call-us">
            <div style={{ display: "flex", alignItems: "center" }}>
              <CallIcon />
              <h1 className="mobile-title-section">Call Us</h1>
            </div>

            <div style={{ marginLeft: "2.25rem" }}>
              <p>(021) 6323444</p>
              <a
                href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
                  text
                )}`}
                target="_blank"
              >
                <button>Whatsapp Our Admin</button>
              </a>
              <br />
              <a href={`mailto:${btnEmail}`} target={"_top"}>
                <button>Email Us Instead</button>
              </a>
            </div>
          </div>

          <div className="mobile-info-location">
            <div style={{ display: "flex", alignItems: "center" }}>
              <LocationOnIcon />
              <h1 className="mobile-title-section">Location</h1>
            </div>

            <div style={{ marginLeft: "2.25rem" }}>
              <p>
                Gedung E-Building Lt. 2 Komplek Harmoni Plaza, Blok I No 1-4 Jl.
                Suryopranoto no. 2, Jakarta Pusat RT.3/RW.8, Petojo Utara
                Gambir, Jakarta, 10160
              </p>
              <button>VISIT US</button>
            </div>
          </div>

          <div className="mobile-opening-hours">
            <div style={{ display: "flex", alignItems: "center" }}>
              <WatchLaterIcon />
              <h1 className="mobile-title-section">Opening Hours</h1>
            </div>

            <div style={{ margin: "0 2.25rem" }}>
              <div className="mobile-opening-hours-section">
                <p>Mon - Fri</p>
                <p>09:00 - 17:00</p>
              </div>
              <div className="mobile-opening-hours-section">
                <p>Sat</p>
                <p>09:00 - 13:00</p>
              </div>
              <div className="mobile-opening-hours-section">
                <p>Sun</p>
                <p>Closed</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-info-email-bottom">
          <h1 className="mobile-title-section">
            Follow Us on <br /> Social Media!
          </h1>
          <div className="mobile-social-media-container">
            <div className="mobile-social-media-icon">
              <InstagramIcon className="mobile-icon-social-media" />
              <EmailIcon className="mobile-icon-social-media" />
              <FacebookIcon className="mobile-icon-social-media" />
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-email-container">
        <img
          src={process.env.PUBLIC_URL + "BG Flowers.png"}
          alt="Flower Background"
        />

        <div className="mobile-email-body">
          <h1>Get The Latest Promotion By Subscribing To Our Newsletter</h1>
          <input
            placeholder="Your Email"
            onChange={(val) => setEmail(val.target.value)}
            value={email}
            className="email"
            type="text"
            name="email"
            id="email"
          />
          <button
            onClick={() => {
              sendEmail(email);
              setEmail("");
            }}
            className="mobile-btn-subscribe"
          >
            Subscribe
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileInfoEmail;
