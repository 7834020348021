import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../contexts/LangContext";
import parser from "html-react-parser";

function JoinSocialClub() {
  const { lang, toogleLang } = useContext(LangContext);

  const SocialClubData = {
    IDN: [
      "<b>Hubungi admin PEACE</b> untuk menjadwalkan janji konsultasi",
      "Kamu bisa <b>coba ikut satu sesi secara gratis</b> di The Social Club",
      "<b>Daftarkan diri</b> sebagai anggota dan <b>lengkapi proses asesmen keterampilan sosial</b>",
      "<b>Selamat bergabung!</b> Kamu akan mendapatkan jadwal bulanan dan Toolkit dari kami",
    ],
    ENG: [
      "<b>Contact PEACE admin</b> to schedule a consultation appointment",
      "You can <b>try joining</b> one session at The Social Club <b>for free</b>",
      "<b>Register as a member</b> and <b>complete the social skill assessment</b> process",
      "<b>Welcome!</b> You will get The Social Club's monthly schedule and Toolkit",
    ],
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>
        {lang === "ENG"
          ? "How to Join The Social Club"
          : "Bagaimana Cara Ikut The Social Club?"}
      </h1>

      <div className="social-club-join-card-container">
        {lang === "ENG"
          ? SocialClubData.ENG.map((val, index) => (
              <div
                style={{ marginTop: `${(index + 1) % 2 == 0 && "2rem"}` }}
                key={index}
                className="social-club-join-card"
              >
                <div className="social-club-join-card-top">{index + 1}</div>
                <div className="social-club-join-card-body">
                  {parser(val)}
                </div>
              </div>
            ))
          : SocialClubData.IDN.map((val, index) => (
              <div
                style={{ marginTop: `${(index + 1) % 2 == 0 && "2rem"}` }}
                key={index}
                className="social-club-join-card"
              >
                <div className="social-club-join-card-top">{index + 1}</div>
                <div className="social-club-join-card-body">
                  {parser(val)}
                </div>
              </div>
            ))}
      </div>

      <div style={{ textAlign: "center" }}>
        <button className="social-club-button-download-form">
          {lang === "IDN"
            ? "Unduh Form Registrasi Disini"
            : "Download The Registration Form Here"}
        </button>
      </div>
    </div>
  );
}

export default JoinSocialClub;
