import React from "react";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center"
      }}
    >
      <Helmet>
        <title>Blog | Peace Wesbite</title>
      </Helmet>
      <h1>Work in progress</h1>
    </div>
  );
};

export default Blog;
