import React, { useEffect, useState } from "react";
import "../../styles/Footer.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import parser from "html-react-parser";
import useWindowSize from "../../customs/useWindowSize";

const Footer = () => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <div className="footer">
      <h1 style={{ width: "100%" }}>
        {isMobile
          ? parser(
              "PEACE (Psycho Educational Assessment<br/>Center of Excellence)"
            )
          : "PEACE (Psycho Educational Assessment Center of Excellence)"}
      </h1>
      <p className="footer-address">
        Gedung E-Building Lt. 2, Komplek Harmoni Plaza, Blok I No 1-4, Jl.
        Suryopranoto no. 2, Jakarta Pusat, RT.3/RW.8, Petojo Utara, Gambir,
        Jakarta, 10160
      </p>

      <div className="footer-whatsapp">
        <WhatsAppIcon />
        <p>087784167880</p>
      </div>

      <div className="footer-phone">
        <LocalPhoneIcon />
        <p>(021) 6323444</p>
      </div>
    </div>
  );
};

export default Footer;
