const sendEmail = async (email, emailSubject, emailText, emailFullName) => {
  const request = {
    subject: emailSubject,
    text: emailText,
    email: email,
    fullName: emailFullName,
  };

  const response = await fetch("https://service.peace.id/email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  const result = await response.json();

  console.log(result);

  return result;
};

export { sendEmail };
