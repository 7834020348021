import React, { useContext, useEffect } from "react";
import { LangContext } from "../../contexts/LangContext";
import { Link } from "react-router-dom";
import parser from "html-react-parser";

const ServiceData = [
  {
    icon: "/banner/psychological-assessments.png",
    titleENG: "Psychoeducational Assessments",
    titleIDN: "Asesmen Psikoedukasi",
  },
  {
    icon: "/banner/social-skills-services.png",
    titleENG: "Social Skill Services",
    titleIDN: "Social Skill Services",
  },
  {
    icon: "/banner/learning-support.png",
    titleENG: "Learning<br/>Support",
    titleIDN: "Learning<br/>Support",
  },
  {
    icon: "/banner/play-and-creative-art-therapy.png",
    titleENG: "Play & Creative Art Therapy",
    titleIDN: "Play & Creative Art Therapy",
  },
  {
    icon: "/banner/occupational-therapy.png",
    titleENG: "Occupational Therapy & Sensory Integration",
    titleIDN: "Occupational Therapy & Sensory Integration",
  },
  {
    icon: "/banner/aba-theraphy.png",
    titleENG: "ABA Therapy<br/>or Applied<br/>Behaviour<br/>Analysis",
    titleIDN: "ABA Therapy<br/>or Applied<br/>Behaviour<br/>Analysis",
  },
  {
    icon: "/banner/speech-language.png",
    titleENG: "Speech &<br/>Language<br/>Services",
    titleIDN: "Speech &<br/>Language<br/>Services",
  },
  {
    icon: "/banner/filial-play.png",
    titleENG: "Filial<br/>Play",
    titleIDN: "Filial<br/>Play",
  },
  {
    icon: "/banner/group-individul-consulting.png",
    titleENG: "Group & Individual Counseling",
    titleIDN: "Group & Individual Counseling",
  },
];

const Help = () => {
  const { lang, toogleLang } = useContext(LangContext);

  return (
    <div className="help">
      <h1 className="help-title-section">{lang === "ENG" ? "HOW CAN WE HELP YOU?" : "BAGAIMANA KAMI DAPAT MEMBANTU ANDA?"}</h1>

      <div className="help-container">
        {ServiceData.map((val, index) => (
          <div key={index} className="help-card-container">
            <img
              className="help-card-background"
              src={`${process.env.PUBLIC_URL + val.icon}`}
              alt={lang === "ENG" ? parser(val.titleENG) : parser(val.titleIDN)}
            />
            <h1>{lang === "ENG" ? parser(val.titleENG) : parser(val.titleIDN)}</h1>
          </div>
        ))}
      </div>

      <div style={{textAlign: "center"}}>
        <Link to={"/services"}>
        <button className="btn-learn-more-service-help">
          {lang === "ENG" ? "Learn More about Our Services" : "Ketahui Lebih Lanjut"}
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Help;
