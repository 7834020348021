import React from "react";
import parser from "html-react-parser";

const ServicesCard = ({ img, title, desc }) => {
  return (
    <div className="services-card">
      <img
        className="services-card-img"
        src={process.env.PUBLIC_URL + "/services/" + img}
        alt="Services icon"
      />

      <div className="services-body">
        <h1 className="services-card-title">{parser(title)}</h1>
        <p className="services-card-desc">{parser(desc)}</p>
      </div>
    </div>
  );
};

export default ServicesCard;
