import React, { useState } from "react";

export const LangContext = React.createContext();

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState("ENG");
  const toogleLang = () => {
    lang === "ENG" ? setLang("IDN") : setLang("ENG");
  };
  return (
    <LangContext.Provider value={{ lang, toogleLang }}>
      {children}
    </LangContext.Provider>
  );
};
