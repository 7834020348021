import Instagram from "../components/Home/Instagram";
import React, { useEffect, useState } from "react";
import Footer from "../components/Home/Footer";
import Banner from "../components/Club/Banner";
import BenefitSocialClub from "../components/Club/BenefitSocialClub";
import JoinSocialClub from "../components/Club/JoinSocialClub";
import OurPrograms from "../components/Club/OurPrograms";
import WhySocialClub from "../components/Club/WhySocialClub";
import MobileJoinSocialClub from "../components/Club/MobileJoinSocialClub";
import useWindowSize from "../customs/useWindowSize";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Helmet } from "react-helmet";

const TheSocialClub = () => {
  const socialMediaData = [
    {
      icon: <InstagramIcon className="icon-social-media" />,
      url: "https://www.instagram.com/thesocclub/",
      alias: "instagram.com/thesocclub",
    },
    {
      icon: <EmailIcon className="icon-social-media" />,
      url: "mailto:psikoedu.peace@gmail.com ",
      alias: "psikoedu.peace@gmail.com",
    },
    {
      icon: <FacebookIcon className="icon-social-media" />,
      url: "https://www.facebook.com/peace.idn",
      alias: "facebook.com/peace.idn",
    },
    {
      icon: <YouTubeIcon className="icon-social-media" />,
      url: "https://www.youtube.com/channel/UCu2WqhxLel5i9FUyS1h1-6A",
      alias: "Youtube PEACE.IDN",
    },
    {
      icon: <LinkedInIcon className="icon-social-media" />,
      url: "https://www.linkedin.com/company/psychoeducationalassessmentcenterofexcellence/people/",
      alias: "LinkedIn PEACE.IDN",
    },
  ];

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);
  
  return (
    <div>
      <Helmet>
        <title>The Social Club | Peace Wesbite</title>
      </Helmet>

      <Banner />
      <OurPrograms />
      <WhySocialClub />
      <BenefitSocialClub />
      {isMobile ? <MobileJoinSocialClub /> : <JoinSocialClub />}
      <Instagram
        data={socialMediaData}
        dataSrc={"2d9de7d3-994f-4f2a-bcae-031ce6d2ac9d#instagram"}
      />
      <Footer />
    </div>
  );
};

export default TheSocialClub;
