import React, { useEffect } from "react";
import { useState } from "react";

const getWindowsSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowSize = () => {
  const [windowsSize, setWindowSize] = useState(getWindowsSize());

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowSize(getWindowsSize());
    });

    return () =>
      window.removeEventListener("resize", () => {
        setWindowSize(getWindowsSize());
      });
  }, []);
  
  return windowsSize;
};

export default useWindowSize;
