import React, { useContext, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { LangContext } from "../../contexts/LangContext";

const testimonialData = {
  ENG: [
    {
      name: "Tian Arief and Efin Fintiana",
      desc: "Parents of Fairuz Khairunnisa (Fay), 19 years old",
      body: "I am very grateful that our child started a series of therapies in PEACE & YIPABK since 2 years ago. Here in PEACE, Fay has been coming to therapy sessions that helped her in many areas compared to other centers. Alhamdulillah, my daughter, Fay, who has Autism, made a lot of progress compared to from before. Nevertheless, we still have a lot of “homework” that still needs to be done for Fay to be able to be independent – this is our goal. At least we have a guide to help Fay to grow and develop well. The rest of Fay’s development, we can only surrender to God.",
    },

    {
      name: "Brenda, 18 years old",
      desc: "Student, London School of Public Relations, Jakarta",
      body: "I learned that I feel very blessed with all the blessings that I received each day. I also learned that teenagers with special needs are very honest and loyal. They say what they meant and they don’t hide anything. From them, I try to be more patient, considerate, understanding, and respect others. Spending time with them made me someone they value quite much which leads me to my growing passion towards special education. Social Group is an amazing experience, I look forward to spend more time with them!",
    },

    {
      name: "AL, 18 years old",
      desc: "The Social Club Participant ",
      body: "I like The Social Club because I can meet my friends. It is very exciting and fun, especially when we had a Costume Party. In Social Group I learn to give comments and I make new friends, girls and boys. I like eating together and having fun time with my friends.",
    },

    {
      name: "Rifky, 17 years old",
      desc: "The Social Club Participant, High School Student",
      body: "I enjoy learning about social skills, meeting friends and learning how to make new friends. I like our friendship session and fun time most!",
    },

    {
      name: "Mark West and Megawati West",
      desc: "Parent of Rifky, 17 years old",
      body: "Our son Rifky’s attendance to social program has given him the confidence and the tools to be able to better communicate with peers and other in social environments.",
    },
  ],

  IDN: [
    {
      name: "Tian Arief and Efin Fintiana",
      desc: "Orang tua dari Fairuz Khairunnisa (Fay), 19 tahun",
      body: "Saya sangat bersyukur karena anak kami memulai serangkaian terapi di PEACE dan YIPABK semenjak 2 tahun lalu. Di PEACE, Fay menghadiri sesi-sesi terapi yang membantunya di berbagai bidang dibandingkan dengan center lainnya. Alhamdulillah, anak kami Fay yang memiliki Autisme sudah menunjukkan kemajuan dari sebelumnya. Meskipun begitu, kami masih punya banyak PR yang perlu dikerjakan agar Fay dapat menjadi mandiri – ini tujuan kami. Setidaknya kami punya pembimbing yang membantu Fay tumbuh dan berkembang dengan baik. Perkembangan Fay selebihnya hanya dapat kami serahkan ke Tuhan. ",
    },
    {
      name: "Brenda, 18 tahun",
      desc: "Mahasiswi, London School of Public Relations, Jakarta",
      body: "Saya belajar bahwa saya sangat dikaruniai dari setiap berkat yang saya terima setiap harinya. Saya juga belajar bahwa remaja berkebutuhan khusus sangatlah jujur dan setia. Mereka berkata apa adanya dan tidak menyembunyikan apapun. Dari mereka, saya mencoba untuk lebih sabar, memikirkan orang lain, memahami, dan menghargai orang di sekitar saya. Waktu bersama mereka membuat saya menjadi seseorang yang berarti untuk mereka. Hal itu yang menuntun saya sampai menemukan passion saya di bidang pendidikan khusus. Pengalaman di Social Group sangatlah luar biasa, saya selalu menanti-nantikan waktu bersama mereka!",
    },
    {
      name: "AL, 18 tahun",
      desc: "Partisipan The Social Club ",
      body: "Saya menyukai The Social Club karena saya dapat bertemu dengan teman-teman saya. Kegiatan di sini sangat menyenangkan, terutama ketika ada Pesta Kostum yang sangat menyenangkan. Di Social Group saya belajar untuk memberikan komentar dan saya mendapatkan teman baru, perempuan dan laki-laki. Saya suka makan bersama dan bersenang-senang dengan teman-teman saya. ",
    },
    {
      name: "Rifky, 17 tahun",
      desc: "Partisipan The Social Club, Siswa SMA",
      body: "Saya menyukai belajar tentang keterampilan sosial, bertemu teman, dan bagaimana caranya menjalin pertemanan dengan orang baru. Saya sangat menyukai Friendship Session dan fun time!",
    },
    {
      name: "Mark West and Megawati West",
      desc: "Orang tua Rifky, 17 years old",
      body: "Keikutsertaan Rifky dalam social program sudah memberikannya kepercayaan diri dan alat agar dapat berkomunikasi lebih baik dengan teman-temannya juga orang lain dalam lingkungan sosial.",
    },
  ],
};
const Testimony = () => {
  const [position, setPosition] = useState(0);
  const animation = useAnimation();
  const { lang, toogleLang } = useContext(LangContext);

  const nextTestimony = () => {
    animation.start({
      x: [0, -5, 0],
      opacity: [1, 0, 1],
    });
    setTimeout(() => {
      if (
        position < testimonialData.ENG.length - 1 ||
        position < testimonialData.IDN.length - 1
      ) {
        setPosition(position + 1);
      } else {
        setPosition(0);
      }
    }, 400);
  };

  return (
    <div className="home-testimonial">
      <img
        className="home-testimonial-banner-img"
        width="100%"
        src={process.env.PUBLIC_URL + "/testimoni.png"}
        alt="Testimoni Section"
      />

      <motion.div animate={animation} className="home-testimonial-body">
        <img
          onClick={nextTestimony}
          className="btn-next-testimony"
          src={process.env.PUBLIC_URL + "/icons/arrow.png"}
          alt="Arrow Left Icon"
        />

        <div>
          <h1>Testimonial</h1>
          <em>
            {lang === "ENG"
              ? testimonialData.ENG[position].body
              : testimonialData.IDN[position].body}
          </em>
          <p>
            <b>
              {lang === "ENG"
                ? testimonialData.ENG[position].name
                : testimonialData.IDN[position].name}
            </b>
          </p>
          <p>
            {lang === "ENG"
              ? testimonialData.ENG[position].desc
              : testimonialData.IDN[position].desc}
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Testimony;
