import React, { useContext, useState } from "react";

import { motion } from "framer-motion";
import { LangContext } from "../../contexts/LangContext";
import { Link } from "react-router-dom";
import useWindowSize from "../../customs/useWindowSize";
import { useEffect } from "react";
import parser from "html-react-parser";

const AlhiData = [
  {
    picture: "ahli1.png",
    title: "Dr. Alice Arianto, Psy. D, CGP, FPCM",
    desc: "Certified Play & Creative Art Therapist<br/>Clinical Supervisor",
  },
  {
    picture: "ahli2.png",
    title: "Kurnia Mega, M.Psi, Psikolog",
    desc: "Clinical Psychologist<br/>Certified Play & Creative Art Therapist",
  },
  {
    picture: "ahli3.png",
    title: "Merry Sadan, S.E.",
    desc: "Center Coordinator<br>Certified Play & Creative Art Therapist<br>Filial Play Coach Mentor",
  },
  {
    picture: "ahli4.png",
    title: "Angelia Kristanti Permana, M. Ed",
    desc: "Educational Consultant",
  },
  {
    picture: "ahli5.png",
    title: "Amabelle Vanalyssa, M.A",
    desc: "Creative Art & Inclusive Education Specialist",
  },
  {
    picture: "ahli6.png",
    title: "Marie Jorie Bautista, SLP",
    desc: "Speech & Language Pathologist",
  }
];
const AhliCarousel = () => {
  const [position, setPosition] = useState(0);
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <>
      {!isMobile ? (
        <div className="home-ahli">
          <div className="home-ahli-left">
            <h1 className="home-ahli-left-title">
              {lang === "ENG"
                ? "We Have Experienced Professionals"
                : "Kami Memiliki Ahli yang Berpengalaman"}
            </h1>
            <Link to={"our-team"}>
              <button className="btn-home-ahli-left">
                {lang === "ENG" ? "Meet Our Experts" : "Temui Pakar Kami"}
              </button>
            </Link>
          </div>

          <div className="home-ahli-right">
            {/* Left arrow button */}
            <button
              className="btn-ahli-arrow"
              onClick={() =>
                position > 0
                  ? setPosition(position - 1)
                  : setPosition(AlhiData.length - 1)
              }
              style={{
                position: "absolute",
                left: "1rem",
                top: "40%",
                zIndex: 2,
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/left.png"}
                alt="Arrow Left"
              />
            </button>

            {AlhiData.map((val, index) => (
              <motion.div
                key={index}
                className="home-ahli-card"
                initial={{ y: -20, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  x: `-${position * 23.5}rem`,
                }}
                transition={{ type: "spring", duration: 2 }}
              >
                <div className="home-ahli-pic-container">
                  <img
                    className="home-ahli-bg"
                    src={process.env.PUBLIC_URL + "/ahli/BG.png"}
                    alt="Background Card Ahli"
                  />
                  <img
                    className="home-ahli-pic"
                    src={process.env.PUBLIC_URL + "/ahli/" + val.picture}
                    alt="Ahli"
                  />
                </div>

                <div className="home-ahli-desc">
                  <h1 className="title">{val.title}</h1>
                  <p className="desc">{parser(val.desc)}</p>
                </div>
              </motion.div>
            ))}

            {/* Right arrow button */}
            <button
              className="btn-ahli-arrow"
              onClick={() =>
                position < AlhiData.length - 1
                  ? setPosition(position + 1)
                  : setPosition(0)
              }
              style={{ position: "absolute", right: "1rem", top: "40%" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/right.png"}
                alt="Right Arrow"
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="mobile-home-ahli">
          <h1 className="mobile-home-ahli-title">
            {lang == "ENG"
              ? "We Have Experienced Professionals"
              : "Kami Memiliki Ahli yang Berpengalaman"}
          </h1>

          <div className="mobile-card-container">
            <button
              onClick={() =>
                position !== 0 ? setPosition(position - 1) : setPosition(0)
              }
              className="mobile-card-button left"
            >
              <img src={process.env.PUBLIC_URL + "left.png"} alt="left arrow" />
            </button>

            {AlhiData.map((ahli , index) => (
              <motion.div
              key={index}
                animate={{
                  position: "relative",
                  x: `-${position * 16}rem`,
                }}
                className="mobile-card-container-card"
              >
                <div className="mobile-card-container-bg-wrapper">
                  <img
                    className="mobile-card-container-background"
                    src={process.env.PUBLIC_URL + "/ahli/BG.png"}
                    alt="Background"
                  />
                  <img
                    className="mobile-card-container-image"
                    src={process.env.PUBLIC_URL + "/ahli/" + ahli.picture}
                    alt="Ahli Picture"
                  />
                </div>

                <div className="mobile-card-container-desc">
                  <h1>{ahli.title}</h1>
                  <p>{parser(ahli.desc)}</p>
                </div>
              </motion.div>
            ))}

            <button
              onClick={() =>
                position < AlhiData.length - 1
                  ? setPosition(position + 1)
                  : setPosition(0)
              }
              className="mobile-card-button right"
            >
              <img
                src={process.env.PUBLIC_URL + "right.png"}
                alt="right arrow"
              />
            </button>
          </div>

          <div style={{textAlign: "center", marginTop: ".5rem"}}>
            <Link to={"our-team"}>
              <button className="btn-banner">
                {lang === "ENG" ? "Meet Our Experts" : "Temui Pakar Kami"}
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default AhliCarousel;
