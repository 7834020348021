import React, { useContext, useEffect, useState } from "react";
import Banner from "../components/About/Banner";
import "../styles/About.css";
import Footer from "../components/Home/Footer";
import { Link } from "react-router-dom";
import { LangContext } from "../contexts/LangContext";
import Procedure from "../components/About/Procedure";
import useWindowSize from "../customs/useWindowSize";
import { Helmet } from "react-helmet";

const aboutData = [
  {
    IDN: [
      {
        title: "Tentang Kami",
        description:
          "Didirikan pada 2013, PEACE berdedikasi untuk membantu anak-anak dan remaja dalam mencapai potensi penuhnya melalui beragam layanan dan asesmen.",
        img: "About1.png",
      },
      {
        title: "Apakah Asesmen Psikoedukasi?",
        description:
          "Serangkaian proses evaluasi untuk menghasilkan gambaran tentang  perkembangan kognitif, kemampuan tumbuh kembang, perilaku, dan kemampuan akademik serta belajar anak. Orang tua akan mendapatkan laporan lengkap tentang gambaran dan profil kemampuan dan kebutuhan anak, disertai dengan saran dan rekomendasi intervensi atau rencana dan strategi belajar yang dibutuhkan oleh setiap anak.",
        img: "About2.jpg",
      },
      {
        title: "Kenapa Asesmen Psikoedukasi Dibutuhkan?",
        description:
          "Asesmen psikoedukasi dibutuhkan untuk mengetahui dan memastikan ada tidaknya kesulitan belajar, masalah atensi/konsentrasi, masalah perilaku dalam belajar ataupun masalah emosi yang dialami oleh anak. Orang tua akan mendapatkan gambaran mengenai kelebihan dan kebutuhan anak di area kognitif, psikologis dan pendidikan.",
        img: "About3.jpg",
      },
    ],

    ENG: [
      {
        title: "About Us",
        description:
          "Established in 2013, PEACE (Psycho Educational Assessment Center of Excellence) is dedicated to helping children and young people in achieving their full potential through a wide range of services and assessments. Services provided by PEACE include assessments, consultations, and various interventions and therapy programs.",
        img: "About1.png",
      },
      {
        title: "What is Psychoeducational Assessment?",
        description:
          "A series of evaluation process to show an estimation of the development of cognitive, developmental, behavioral, and academic skills of children. Parents will acquire a complete report on the child’s profile including the child's abilities and needs, accompanied by suggestions and recommendations for interventions or learning plans and strategies needed by each child.",
        img: "About2.jpg",
      },
      {
        title: "Why Do We Need Psychoeducational Assessment?",
        description:
          "Psychoeducational assessment is needed to identify and ensure whether there are learning difficulties, attention or concentration problems, behavioral issues in learning, or emotional problems experienced by children. Parents will get an idea of the strengths and needs of children in the cognitive, psychological, and educational areas.",
        img: "About3.jpg",
      },
    ],
  },
];

const About = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);
  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <div className="about-page-container">
      <Helmet>
        <title>About | Peace Wesbite</title>
      </Helmet>
      {aboutData.map((data, index) =>
        lang === "IDN"
          ? data.IDN.map((val, index) =>
              index === 1 ? (
                <>
                  <Procedure />
                  <Banner
                    img={val.img}
                    title={val.title}
                    text={val.description}
                    position={"right"}
                  >
                    <Link to="/services">
                      <button className="about-btn-learn-more">
                        Lebih Lanjut
                      </button>
                    </Link>
                  </Banner>
                </>
              ) : (
                <Banner
                  img={val.img}
                  title={val.title}
                  text={val.description}
                  position={"left"}
                />
              )
            )
          : data.ENG.map((val, index) =>
              index === 1 ? (
                <>
                  <Procedure />
                  <Banner
                    img={val.img}
                    title={val.title}
                    text={val.description}
                    position={"right"}
                  >
                    <Link to="/services">
                      <button className="about-btn-learn-more">
                        Learn More
                      </button>
                    </Link>
                  </Banner>
                </>
              ) : (
                <Banner
                  color={
                    index == 0 && isMobile
                      ? "#CAA4CC"
                      : index == 2 && isMobile
                      ? "white"
                      : null
                  }
                  img={val.img}
                  title={val.title}
                  text={val.description}
                  position={"left"}
                />
              )
            )
      )}
      <Footer />
    </div>
  );
};

export default About;
