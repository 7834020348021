import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../contexts/LangContext";
import useWindowSize from "../../customs/useWindowSize";
import { animate, motion } from "framer-motion";

const NavbarMenu = [
  {
    titleENG: "Home",
    titleIDN: "BERANDA",
    slug: "/",
  },
  {
    titleENG: "About",
    titleIDN: "TENTANG",
    slug: "/about",
  },
  {
    titleENG: "Services",
    titleIDN: "LAYANAN",
    slug: "/services",
  },
  {
    titleENG: "Our Team",
    titleIDN: "TIM KAMI",
    slug: "/our-team",
  },
  {
    titleENG: "The Social Club",
    titleIDN: "THE SOCIAL CLUB",
    slug: "/the-social-club",
  },
  {
    titleENG: "Blog",
    titleIDN: "BLOG",
    slug: "/blog",
  },
  {
    titleENG: "Contact",
    titleIDN: "KONTAK",
    slug: "/contact",
  },
];
const Navbar = () => {
  const size = useWindowSize();
  const { lang, toogleLang } = useContext(LangContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  const variants = {
    open: { opacity: 1, y: 0 },
    close: { opacity: 0, transitionEnd: { y: -400 } },
  };

  return (
    <div className="navbar">
      <Link to={"/"}>
        <img
          className="navbar-icon"
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="Peace Logo"
        />
      </Link>

      <div onClick={() => setIsOpen(!isOpen)} className="m-hamburger-menu">
        <img
          src={process.env.PUBLIC_URL + "hamburger.png"}
          alt="Hamburger Menu"
        />
      </div>

      {isMobile ? (
        <motion.ul
          transition={{ type: "spring", duration: 0.35 }}
          variants={variants}
          animate={isOpen ? "open" : "close"}
          // style={{ display: `${isOpen ? "flex" : "none"}` }}
          className="navbar-container-list"
        >
          {NavbarMenu.map((menu, index) => (
            <li
              onClick={() => setIsOpen(false)}
              key={index}
              className="navbar-list"
            >
              <Link className="navbar-link" to={menu.slug}>
                {lang === "ENG"
                  ? menu.titleENG.toUpperCase()
                  : menu.titleIDN.toUpperCase()}
              </Link>
            </li>
          ))}

          <div className="btn-language">
            <img
              onClick={toogleLang}
              src={
                lang === "ENG"
                  ? process.env.PUBLIC_URL + "/IDN.png"
                  : process.env.PUBLIC_URL + "/ENG.png"
              }
              alt="Language icon"
            />
          </div>
        </motion.ul>
      ) : (
        <motion.ul className="navbar-container-list">
          {NavbarMenu.map((menu, index) => (
            <li
              onClick={() => setIsOpen(false)}
              key={index}
              className="navbar-list"
            >
              <Link className="navbar-link" to={menu.slug}>
                {lang === "ENG"
                  ? menu.titleENG.toUpperCase()
                  : menu.titleIDN.toUpperCase()}
              </Link>
            </li>
          ))}

          <div className="btn-language">
            <img
              onClick={toogleLang}
              src={
                lang === "ENG"
                  ? process.env.PUBLIC_URL + "/IDN.png"
                  : process.env.PUBLIC_URL + "/ENG.png"
              }
              alt="Language icon"
            />
          </div>
        </motion.ul>
      )}
    </div>
  );
};

export default Navbar;
