import React, { useContext, useEffect } from "react";
import "../../styles/Instagram.css";
import parser from "html-react-parser";
import { LangContext } from "../../contexts/LangContext";

const Instagram = ({ dataSrc, data }) => {

  const { lang, toogleLang } = useContext(LangContext);

  return (
    <div className="home-social-media">
      <div className="instagram-widget" loading="lazy" data-mc-src={dataSrc}>
        <div className="remove-watermark"></div>
      </div>

      <div className="home-social-info">
        <h1 className="home-social-info-title">
          {lang === "ENG"
            ? parser("Follow us on <br/>Social Media")
            : parser("Temui Kami di <br/>Media Sosial")}
        </h1>
        <div className="home-social-info-container">
          {data.map((e, index) => (
            <div key={index} className="home-social-info-item">
              {e.icon}
              <a className="home-social-info-link" href={e.url} target="_blank">
                {e.alias}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instagram;
