import React, { useContext } from "react";
import { LangContext } from "../../contexts/LangContext";
import "../../styles/Services.css";

const Banner = () => {
  const { lang, toogleLang } = useContext(LangContext);
  return (
    <div>
      <div className="services-banner-container">
        <img
          className="services-banner"
          src={process.env.PUBLIC_URL + "/services/Services.jpg"}
          alt="Services banner"
        />

        <h1 className="services-title">{lang === "ENG" ? "SERVICES" : "LAYANAN"}</h1>
      </div>
    </div>
  );
};

export default Banner;
