import React, { useContext, useEffect } from "react";
import { LangContext } from "../../contexts/LangContext";

const WhySocialClubData = {
  IDN: [
    "Meningkatkan keterampilan sosial dan kemampuan berkomunikasi",
    "Meningkatkan kepercayaan diri",
    "Belajar untuk bertanggung jawab dan mandiri",
    "Meningkatkan kesadaran diri",
    "Mengembangkan kemampuan bantu diri (self-help skills)",
  ],
  ENG: [
    " Improve social and communication skills",
    " Improve self-esteem",
    "Learn to be responsible and independent",
    "Increase self-awareness",
    "Develop self-help skills",
  ],
};

const WhySocialClub = () => {
  const { lang, toogleLang } = useContext(LangContext);

  return (
    <div className="club-why-container">
      <div className="club-why-banner">
        <h1 className="club-why-banner-title">
          {lang === "ENG"
            ? "Why Join The Social Club?"
            : "Mengapa Harus Ikut The Social Club?"}
        </h1>

        {lang === "ENG"
          ? WhySocialClubData.ENG.map((val , key) => (
              <p key={key} className="club-why-banner-list">{val}</p>
            ))
          : WhySocialClubData.IDN.map((val , key) => (
              <p key={key} className="club-why-banner-list">{val}</p>
            ))}
      </div>

      <div className="club-why-image">
        <img
          className="club-why-image-bg"
          src={process.env.PUBLIC_URL + "/club/why-social-bg.png"}
          alt="Social Banner Image"
        />
        <img
          className="club-why-image-kids"
          src={process.env.PUBLIC_URL + "/club/why-social-front.png"}
          alt="Social Banner Image"
        />
      </div>
    </div>
  );
};

export default WhySocialClub;
