import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/contact.css";
import { LangContext } from "../contexts/LangContext";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parser from "html-react-parser";
import { sendEmail } from "../utils/emailService";
import Footer from "../components/Home/Footer";

const Contact = () => {
  const contactData = [
    {
      icon: <LocalPhoneIcon className="contact-banner-info-icon" />,
      titleIDN: "TELEPON",
      titleENG: "PHONE",
      descIDN:
        "Hubungi kami di nomor ini selama jam kerja dan kami akan menjawab pertanyaan anda",
      descENG:
        "Contact us directly in this number during office hours and we would gladly answer your questions",
      extra: "WA: (+62) 87784167880<br/>Telp: (021) 6323444 ",
    },
    {
      icon: <EmailIcon className="contact-banner-info-icon" />,
      titleIDN: "EMAIL",
      titleENG: "EMAIL",
      descIDN:
        "Email kami untuk membuat janji atau jika ada pertanyaan mengenai program kami",
      descENG:
        "Shoot us an email to schedule an appointment or if you have any inquiries about our programs",
      extra: "psikoedu.peace@gmail.com",
    },
    {
      icon: <LocationOnIcon className="contact-banner-info-icon" />,
      titleIDN: "LOKASI",
      titleENG: "LOCATION",
      descIDN:
        "Email kami untuk membuat janji atau jika ada pertanyaan mengenai program kami",
      descENG:
        "E-Learning Building Fl. 2 Komplek Harmoni Plaza Blok I No 1-4 Jl. Suryopranoto no. 2 Jakarta Pusat",
    },
  ];

  const [contact, setContact] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const { lang, toogleLang } = useContext(LangContext);

  return (
    <div>
      <Helmet>
        <title>Contact | Peace Wesbite</title>
      </Helmet>

      <div className="contact-banner-container">
        <img
          className="contact-banner-image"
          src={process.env.PUBLIC_URL + "/contact/banner.png"}
        />
        <h1>{lang === "ENG" ? "CONTACT US" : "HUBUNGI KAMI"}</h1>
        <p>{lang === "ENG" ? "Get in touch" : "Silahkan menghubungi kami"}</p>
      </div>

      <div className="contact-banner-info-container">
        {contactData.map((val, key) => (
          <div key={key} className="contact-banner-info-card">
            {val.icon}
            <h1 className="contact-banner-info-card-title">
              {lang === "ENG" ? val.titleENG : val.titleIDN}
            </h1>
            <p className="contact-banner-info-card-desc">
              {lang === "ENG" ? val.descENG : val.descIDN}
            </p>
            {val.hasOwnProperty("extra") && (
              <p className="contact-banner-info-card-notes">
                {parser(val.extra)}
              </p>
            )}
          </div>
        ))}
      </div>

      <div className="contact-info-email-container">
        <div className="contact-info-email-container-left">
          <div className="contact-info-form-title">
            <h1 style={{ margin: 0 }}>LEAVE US YOUR INFO</h1>
            <p style={{ margin: 0 }}>and we will get back to you.</p>
          </div>

          <div className="contact-info-form-container">
            <div>
              <input
                value={contact.fullName}
                onChange={(val) =>
                  setContact({
                    ...contact,
                    fullName: val.target.value,
                  })
                }
                style={{ flex: 1 }}
                placeholder="Full name*"
                type="text"
                name="full_name"
                id="full_name"
              />
              <input
                value={contact.email}
                onChange={(val) =>
                  setContact({
                    ...contact,
                    email: val.target.value,
                  })
                }
                style={{ flex: 1 }}
                placeholder="E-mail*"
                type="text"
                name="email"
                id="email"
              />
            </div>

            <input
              value={contact.subject}
              onChange={(val) =>
                setContact({
                  ...contact,
                  subject: val.target.value,
                })
              }
              placeholder="Subjects*"
              type="text"
              name="subject"
              id="subject"
            />
            <textarea
              value={contact.message}
              onChange={(val) =>
                setContact({
                  ...contact,
                  message: val.target.value,
                })
              }
              placeholder="Messages*"
              type="text"
              name="messages"
              id="messages"
            />
            <button
              onClick={() => {
                sendEmail(
                  contact.email,
                  contact.subject,
                  contact.message,
                  contact.fullName
                );

                setContact({
                  email: "",
                  fullName: "",
                  message: "",
                  subject: "",
                });
              }}
              className="btn-submit-form"
            >
              SUBMIT NOW
            </button>
          </div>
        </div>

        <div className="contact-info-email-container-right">
          <h1
            className="contact-info-email-container-right-title"
            style={{ margin: 0 }}
          >
            WORKING
            <br />
            HOURS
          </h1>
          <p className="contact-info-email-container-right-desc">
            We’d appreciate if you make an appointment prior to your visit
          </p>

          <div className="contact-info-working-hours">
            <div className="contact-info-working-day-sections">
              <p>Monday</p>
              <p>Tuesday</p>
              <p>Wednesday</p>
              <p>Thursday</p>
              <p>Friday</p>
              <p>Saturday</p>
              <p>Sunday</p>
            </div>

            <div className="contact-info-working-hours-sections">
              <p>9:00 AM – 5:00 PM</p>
              <p>9:00 AM – 5:00 PM</p>
              <p>9:00 AM – 5:00 PM</p>
              <p>9:00 AM – 5:00 PM</p>
              <p>9:00 AM – 5:00 PM</p>
              <p>9:00 AM – 1:00 PM</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default Contact;
