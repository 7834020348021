import React, { useContext } from "react";
import { LangContext } from "../../contexts/LangContext";
import "../../styles/SocialClub.css";

const BannerData = {
  IDN: {
    title1: "The Social Club",
    desc1:
      "The Social Club adalah komunitas inklusif untuk anak dan remaja. The Social Club mengajak anda untuk memahami pentingnya membangun pertemanan, mengembangkan keterampilan sosial dan menjadi bagian dari komunitas inklusif melalui berbagai macam aktivitas. Program-program kami dirancang untuk pembelajaran peer-to-peer yang interaktif dan menyenangkan.",
    title2: "Partisipan Kami adalah",
    desc2:
      "Individu yang memiliki kesulitan dalam bersosialisasi atau membangun pertemanan dan individu dengan kebutuhan khusus mulai dari 4 tahun hingga remaja. ",
  },

  ENG: {
    title1: "The Social Club",
    desc1:
      "Is an inclusive community for children and young people. The Social Club invites its members to understand the importance of building friendships, developing social skills, and being a part of inclusive community through many activities. Our programs in The Social Club are designed for peer-to-peer learning in interactive and fun ways.",
    title2: "Our Participants Are",
    desc2:
      "Individuals who experience difficulty in socializing or building friendship and individuals with special needs starting from age 4 years old to young adults.",
  },
};

const Banner = () => {
  const { lang, toogleLang } = useContext(LangContext);

  return (
    <div className="club-banner-container">
      <div className="club-image-conatiner">
        <img
          src={process.env.PUBLIC_URL + "/club/social-img.png"}
          alt="Social Club Image"
        />
      </div>

      {lang === "ENG" ? (
        <div className="club-banner-text-container">
          <h1 className="club-banner-title">{BannerData.ENG.title1}</h1>
          <p className="club-banner-desc">{BannerData.ENG.desc1}</p>

          <h1 className="club-banner-title">{BannerData.ENG.title2}</h1>
          <p className="club-banner-desc">{BannerData.ENG.desc2}</p>
        </div>
      ) : (
        <div className="club-banner-text-container">
          <h1 className="club-banner-title">{BannerData.IDN.title1}</h1>
          <p className="club-banner-desc">{BannerData.IDN.desc1}</p>

          <h1 className="club-banner-title">{BannerData.IDN.title2}</h1>
          <p className="club-banner-desc">{BannerData.IDN.desc2}</p>
        </div>
      )}
    </div>
  );
};

export default Banner;
