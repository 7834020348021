import React, { useContext } from "react";
import { LangContext } from "../../contexts/LangContext";
import parser from "html-react-parser";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

const MobileJoinSocialClub = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const SocialClubData = {
    IDN: [
      "<b>Hubungi admin PEACE</b> untuk menjadwalkan janji konsultasi",
      "Kamu bisa <b>coba ikut satu sesi secara gratis</b> di The Social Club",
      "<b>Daftarkan diri</b> sebagai anggota dan <b>lengkapi proses asesmen keterampilan sosial</b>",
      "<b>Selamat bergabung!</b><br/> Kamu akan mendapatkan jadwal bulanan dan <br/>Toolkit dari kami",
    ],
    ENG: [
      "<b>Contact PEACE admin</b> to schedule a consultation appointment",
      "You can <b>try joining</b> one session at The Social Club<br/> <b>for free</b>",
      "<b>Register as a member</b> and <b>complete the social skill assessment</b> process",
      "<b>Welcome!</b> You will get The Social Club's monthly schedule and Toolkit",
    ],
  };

  return (
    <div className="mobile-join-social-container">
      <h1 className="mobile-join-social-club-title">
        {lang === "ENG"
          ? "How to Join The Social Club?"
          : "Bagaimana Cara Ikut The Social Club?"}
      </h1>

      <div className="mobile-card-join-container">
        {lang === "ENG"
          ? SocialClubData.ENG.map((val, index) => (
              <div
                key={index}
                className={`mobile-social-card-container ${
                  (index + 1) % 2 === 0 ? "left" : ""
                }`}
              >
                <h1 className={`mobile-social-card-container-number`}>
                  {index + 1}
                </h1>
                <p className="mobile-social-card-container-desc">
                  {parser(val)}
                </p>
              </div>
            ))
          : SocialClubData.IDN.map((val, index) => (
              <div
                key={index}
                className={`mobile-social-card-container ${
                  (index + 1) % 2 === 0 ? "left" : ""
                }`}
              >
                <h1 className={`mobile-social-card-container-number`}>
                  {index + 1}
                </h1>
                <p className="mobile-social-card-container-desc">
                  {parser(val)}
                </p>
              </div>
            ))}

        <a className="mobile-social-btn-form-download" href="http://">
          {lang === "IDN"
            ? "Unduh Form Registrasi Disini"
            : "Download The Registration Form Here"}
        </a>

        <div className="mobile-social-club-media-container">
          <h1 className="mobile-social-club-media-title">
            Follow Us on Social Media!
          </h1>
          <div className="mobile-social-club-media-list">
            <InstagramIcon className="icon-social-media" />
            <EmailIcon className="icon-social-media" />
            <FacebookIcon className="icon-social-media" />
          </div>
          <img
            className="mobile-social-club-media-icon"
            src={process.env.PUBLIC_URL + "/club/social-media.png"}
            alt="Our program"
          />
        </div>
      </div>
    </div>
  );
};

export default MobileJoinSocialClub;
