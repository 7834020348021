import React from "react";
import Navbar from "./components/Home/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import TheSocialClub from "./pages/TheSocialClub";
import OurTeam from "./pages/OurTeam";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import { LangProvider } from "./contexts/LangContext";
import { FilterProvider } from "./contexts/FilterContext";
import ScrollToTop from "./routers/ScrollToTop";

function App() {
  return (
    <BrowserRouter className="App">
      <LangProvider>
        <FilterProvider>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/our-team" element={<OurTeam />} />
              <Route path="/the-social-club" element={<TheSocialClub />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </ScrollToTop>
        </FilterProvider>
      </LangProvider>
    </BrowserRouter>
  );
}

export default App;
