import React, { useContext, useState } from "react";
import { LangContext } from "../../contexts/LangContext";
import { Link } from "react-router-dom";
import parser from "html-react-parser";
import useWindowSize from "../../customs/useWindowSize";
import { useEffect } from "react";

const Banner = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  const HomeMetaData = {
    titleENG: "Supporting<br/>Well-Being<br/>For Children<br/>And Parents",
    MtitleENG: "Supporting Well-Being <br/>For Children And Parents",
    buttonENG: "Learn More About Us!",
    titleIDN: "Mendukung<br/> Kesejahteraan<br/>Anak dan<br/>Orang Tua",
    MtitleIDN: "Mendukung Kesejahteraan <br/>Anak dan Orang Tua",
    buttonIDN: "Ketahui lebih tentang kami!",
  };
  return (
    <div className="banner-video">
      <div className="overlay-video"></div>
      <video autoPlay loop muted>
        <source src={process.env.PUBLIC_URL + "banner.mp4"} type="video/mp4" />
      </video>

      <div className="banner-title">
        <h1 className="home-banner-title">
          {!isMobile
            ? lang === "ENG"
              ? parser(HomeMetaData.titleENG)
              : parser(HomeMetaData.titleIDN)
            : lang === "ENG"
            ? parser(HomeMetaData.MtitleENG)
            : parser(HomeMetaData.MtitleIDN)}
        </h1>

        <Link style={{ textDecoration: "none", color: "white" }} to={"about"}>
          <button className="btn-banner">
            {lang === "ENG" ? HomeMetaData.buttonENG : HomeMetaData.buttonIDN}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Banner;
