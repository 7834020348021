import React, { useContext } from "react";
import { LangContext } from "../../contexts/LangContext";

const Banner = () => {
  const { lang, toogleLang } = useContext(LangContext);
  return (
    <div className="team-banner-container">
      <img
        className="team-banner"
        src={process.env.PUBLIC_URL + "/team/TeamBanner.png"}
        alt="team banner"
      />

      <h1 className="team-banner-title">{lang === "ENG" ? "OUR TEAM" : "TIM KAMI"}</h1>
    </div>
  );
};

export default Banner;
