import React, { useEffect } from "react";
import { LangContext } from "../../contexts/LangContext";

const Banner = ({ img, title, text, position, color, children }) => {
  const { lang, toogleLang } = LangContext;

  return (
    <div style={{ backgroundColor: color }} className="about-banner-container">
      {color == null && (
        <img
          className={`about-banner-img ${
            position === "left" ? "right" : "left"
          }`}
          src={process.env.PUBLIC_URL + img}
          alt="About Banner"
        />
      )}

      <div
        className={
          position === "right"
            ? "about-banner-body right"
            : "about-banner-body left"
        }
      >
        <h1 className="about-banner-title">{title}</h1>
        <p className="about-banner-text">{text}</p>
        {children}
      </div>
    </div>
  );
};

export default Banner;
