import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { LangContext } from "../../contexts/LangContext";
import useWindowSize from "../../customs/useWindowSize";

const OurProgramData = {
  ENG: [
    {
      img: "program1.png",
      title: "Social Skills Group Session",
      desc: "Interactive session to help children and teenager social and communication skill. Participant will be put in groups with friends their age, they will learn the strategy and practice their social skills in a group setting.",
    },
    {
      img: "program2.png",
      title: "Buddies Program",
      desc: "An opportunity for students (high school to university) to be a volunteer as The Social Club’s participants learning partner and role model through interactive sessions and activity.",
    },
    {
      img: "program3.png",
      title: "Social Class",
      desc: "Sessions for children and teenagers to freely express themselves through creative art and dance. Participants are very much allowed to explore and try various media and materials.",
    },
    {
      img: "program4.png",
      title: "Parents Support Group",
      desc: "Sessions for parents to meet and interact with a small group supported by facilitators to overcome challenges parents are currently facing.",
    },
  ],

  IDN: [
    {
      img: "program1.png",
      title: "Social Skills Group Session",
      desc: "Sesi interaktif untuk membantu keterampilan sosial dan komunikasi anak serta remaja. Strategi keterampilan sosial akan diajarkan dan dipraktikkan dalam pengaturan kelompok kecil dengan teman sebaya.",
    },
    {
      img: "program2.png",
      title: "Buddies Program",
      desc: "The Social Club menawarkan kesempatan untuk mahasiswa dan siswa sekolah untuk menjadi sukarelawan sebagai Buddies yang akan menjadi partner belajar dan panutan (role model) melalui sesi interaktif yang diadakan oleh The Social Club.",
    },
    {
      img: "program3.png",
      title: "Social Class",
      desc: "Sesi untuk memberi kebebasan berekspresi melalui seni dan tari kreatif untuk anak-anak dan remaja. Para anggota diberi kesempatan untuk bereksplorasi dan mencoba berbagai media serta bahan.",
    },
    {
      img: "program4.png",
      title: "Parents Support Group",
      desc: "Sesi di mana orang tua dapat saling bertemu dalam setting group kecil dan didukung oleh fasilitator support group untuk dapat melalui proses dan tantangan yang sedang dihadapi bersama-sama.",
    },
  ],
};

const OurPrograms = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(useWindowSize() < 992);

  useEffect(() => {
    setIsMobile(size < 992);
  }, [size]);

  return (
    <div className="club-programs-container">
      <div className="club-programs-container-title-wrapper">
        <img
          className="club-program-icon-carousel"
          src={process.env.PUBLIC_URL + "/club/OurProgram.png"}
          alt="Icon Our Program"
        />
        <h1 className="club-programs-container-title">Our Programs</h1>
      </div>

      <div
        style={{
          gridTemplateColumns: `repeat(${OurProgramData.ENG.length}, 20rem`,
        }}
        className="club-programs-card-container"
      >
        {lang === "ENG"
          ? OurProgramData.ENG.map((val, index) => (
              <div key={index} className="club-program-card-body">
                <div className="club-program-card-img">
                  <img
                    src={process.env.PUBLIC_URL + "/club/" + val.img}
                    alt="program icon"
                  />
                </div>
                <h1 className="club-program-card-title">{val.title}</h1>
                <p className="club-program-card-desc">{val.desc}</p>
              </div>
            ))
          : OurProgramData.IDN.map((val, index) => (
              <div key={index} className="club-program-card-body">
                <div className="club-program-card-img">
                  <img
                    src={process.env.PUBLIC_URL + "/club/" + val.img}
                    alt="program icon"
                  />
                </div>
                <h1 className="club-program-card-title">{val.title}</h1>
                <p className="club-program-card-desc">{val.desc}</p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default OurPrograms;
