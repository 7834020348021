import React, { useContext } from "react";
import { LangContext } from "../../contexts/LangContext";
import parser from "html-react-parser";
import useWindowSize from "../../customs/useWindowSize";
import { useEffect } from "react";
import { useState } from "react";

const clubBenefitData = {
  ENG: [
    "Special discounts for various Social Club activities",
    "Social Club Newsletter",
    "New friends and an inclusive community",
    "A membership card that can be used to get other discounts and benefits",
    "A special gift on your child’s birthday",
  ],
  IDN: [
    "Diskon khusus untuk berbagai aktivitas The Social Club",
    "Newsletter The Social Club",
    "Teman baru dan komunitas inklusif",
    "Kartu keanggotaan yang dapat digunakan untuk memperoleh<br/>diskon dan keuntungan lainnya",
    "Kado khusus pada ulang tahun anak",
  ],
};

const BenefitSocialClub = () => {
  const { lang, toogleLang } = useContext(LangContext);
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(size.width < 992);

  useEffect(() => {
    setIsMobile(size.width < 992);
  }, [size]);

  return (
    <div>
      <div className="club-benefit-top">
        <div className="club-benefit-pic-1">
          <img
            src={process.env.PUBLIC_URL + "/benefit/benefit-1.jpg"}
            alt="Benefit picture"
          />
        </div>
        <div className="club-benefit-pic-2">
          <img
            src={process.env.PUBLIC_URL + "/benefit/benefit-2.jpg"}
            alt="Benefit picture"
          />
        </div>
        <div className="club-benefit-info-container">
          <h1 className="club-benefit-info-container-title">
            {lang === "ENG"
              ? parser(
                  isMobile
                    ? "Benefit of The Social Club Participant"
                    : "Benefit of The Social<br/> Club Participant"
                )
              : parser(
                  isMobile
                    ? "Keuntungan Partisipan The Social Club"
                    : "Keuntungan<br/>Partisipan<br/>The Social Club"
                )}
          </h1>
          <ul>
            {lang === "ENG"
              ? clubBenefitData.ENG.map((val, index) => (
                  <li key={index}>{parser(val)}</li>
                ))
              : clubBenefitData.IDN.map((val, index) => (
                  <li key={index}>{parser(val)}</li>
                ))}
          </ul>
        </div>
      </div>

      {!isMobile ? (
        <div className="club-benefit-bottom">
          <div className="club-benefit-pic-3">
            <img
              src={process.env.PUBLIC_URL + "/benefit/benefit-3.jpg"}
              alt="Benefit banner picture"
            />
          </div>
          <div className="club-benefit-pic-4">
            <img
              src={process.env.PUBLIC_URL + "/benefit/benefit-4.jpg"}
              alt="Benefit banner picture"
            />
          </div>
        </div>
      ) : (
        <div className="mobile-club-benefit-img-container">
          <img
            src={process.env.PUBLIC_URL + "/benefit/" + "benefit-photos.png"}
            alt="Benefit Photos"
          />
        </div>
      )}
    </div>
  );
};

export default BenefitSocialClub;
